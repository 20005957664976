import { reactive } from '@vue/composition-api';

import { DRAWER_TAB } from '@/components/builder-drawers/drawers/constants';
import {
	GLOBAL_STYLES_DRAWER,
	USER_STYLES_DRAWER,
} from '@/store/builder/constants/drawers';

const DRAWER_TAB_DEFAULT_STATE = {
	[GLOBAL_STYLES_DRAWER]: DRAWER_TAB[GLOBAL_STYLES_DRAWER][0],
	[USER_STYLES_DRAWER]: DRAWER_TAB[USER_STYLES_DRAWER][0],
};

export const useDrawerTabs = () => {
	const currentTabs = reactive(DRAWER_TAB_DEFAULT_STATE);

	const changeCurrentTab = ({
		drawer,
		tab = null,
		tabId = null,
	}) => {
		currentTabs[drawer] = tab
			?? DRAWER_TAB[drawer].find((drawerTab) => drawerTab.id === tabId);
	};

	return {
		changeCurrentTab,
		currentTabs,
	};
};
