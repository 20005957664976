<template>
	<div>
		<ZyroTabs
			:tabs="availableTabs"
			:current-tab="currentTab"
			class="drawer__tabs"
			@change="changeCurrentTab({
				drawer: GLOBAL_STYLES_DRAWER,
				tab: $event
			})"
		/>

		<Transition name="slide-right">
			<Component
				:is="currentTab.id"
				@change-page="$emit('change-page', $event)"
			/>
		</Transition>
	</div>
</template>

<script>
import { mapGetters } from 'vuex';

import { GLOBAL_STYLES_DRAWER_TABS } from '@/components/builder-drawers/drawers/constants';
import { useDrawerTabs } from '@/components/builder-drawers/drawers/use/useDrawerTabs';
import { GLOBAL_STYLES_DRAWER } from '@/store/builder/constants/drawers';
import { isDevelopment } from '@/utils/flags';

const ZYRO_USER_TAB = 'AllStyles';

export default {
	components: {
		Colors: () => import('@/components/builder-drawers/drawers/partials/globalStylesDrawer/colors/Colors.vue'),
		AllStyles: () => import('@/components/builder-drawers/drawers/partials/globalStylesDrawer/typography/AllStyles.vue'),
		Typography: () => import('@/components/builder-drawers/drawers/partials/globalStylesDrawer/typography/Typography.vue'),
		Elements: () => import('@/components/builder-drawers/drawers/partials/globalStylesDrawer/elements/Elements.vue'),
	},
	setup() {
		const {
			currentTabs,
			changeCurrentTab,
		} = useDrawerTabs();

		return {
			currentTabs,
			changeCurrentTab,
			GLOBAL_STYLES_DRAWER_TABS,
			GLOBAL_STYLES_DRAWER,
		};
	},
	computed: {
		...mapGetters('user', ['isZyroUser']),
		currentTab() {
			return this.currentTabs[GLOBAL_STYLES_DRAWER];
		},
		availableTabs() {
			if (this.isZyroUser || isDevelopment) {
				return GLOBAL_STYLES_DRAWER_TABS;
			}

			return GLOBAL_STYLES_DRAWER_TABS.filter((tab) => tab.id !== ZYRO_USER_TAB);
		},
	},
};
</script>

<style lang="scss" scoped>
@import '@/components/builder-drawers/drawers/StylesDrawer.scss';
</style>
