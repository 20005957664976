import i18n from '@/i18n/setup';
import {
	USER_STYLES_DRAWER,
	GLOBAL_STYLES_DRAWER,
} from '@/store/builder/constants/drawers';

export const COLORS_TAB_ID = 'Colors';
export const TYPOGRAPHY_TAB_ID = 'Typography';
export const ELEMENTS_TAB_ID = 'Elements';
export const ALL_STYLES_TAB_ID = 'AllStyles';
export const GLOBAL_STYLES_DRAWER_TABS = [
	{
		id: COLORS_TAB_ID,
		title: 'Colors',
	},
	{
		id: TYPOGRAPHY_TAB_ID,
		title: 'Typography',
	},
	{
		id: ELEMENTS_TAB_ID,
		title: 'Elements',
	},
	{
		id: ALL_STYLES_TAB_ID,
		title: 'AllStyles',
	},
];

export const COLOR_SETS_LIBRARY_TAB_ID = 'ColorSetsLibrary';
export const TYPOGRAPHY_STYLES_LIST_TAB_ID = 'TypographyStyleList';
export const USER_STYLES_DRAWER_TABS = [
	{
		id: COLOR_SETS_LIBRARY_TAB_ID,
		title: i18n.t('common.colors'),
	},
	{
		id: TYPOGRAPHY_STYLES_LIST_TAB_ID,
		title: i18n.t('common.text'),
	},
];

export const DRAWER_TAB = {
	[GLOBAL_STYLES_DRAWER]: GLOBAL_STYLES_DRAWER_TABS,
	[USER_STYLES_DRAWER]: USER_STYLES_DRAWER_TABS,
};
